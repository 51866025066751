import React from 'react';
import { Box } from 'grommet'

const About = () => {
  return <Box
      direction="row"
      align="center"
      margin="0px"
      flex="grow"
      className="indexContainer"
      animation={["fadeIn", "slideUp"]}
    >
      <div className="indexHero pageHero">
        <h1>About Me</h1>
        <p>
            I'm Skylar, a 25-year-old non-binary woman with a passion for nonprofits and political campaigns. I love operations, making sure trains run on time, and planning all the things that pop up at the last minute.
        </p>
        <p>
            I live in Walnut Creek, California with my husband and the cutest doggo ever, Panda. I owe my existence to Dr. Pepper and Wendy's.
        </p>
      </div>
    </Box>
};

export default About;
